@font-face {
  font-family: "Graphik";
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/GraphikLight.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/GraphikRegular.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/GraphikMedium.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/GraphikBold.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 900;
  font-style: normal;
  src: url("./assets/fonts/GraphikBlack.otf");
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
